import {AfterViewInit, Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {MenuService} from './menu.service';
import {MenuItem} from './menu-item.model';
import {Router} from '@angular/router';
import {UntypedFormGroup} from '@angular/forms';
import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {AuthenticationService} from '../../../../application/authentication/authentication.service';
import {zip} from 'rxjs';
import {TrabajadorService} from '../../../../shared/services/trabajador.service';
import {InformacionTrabajador} from '../../../../shared/services/trabajador.model';
import {EchoService} from '../../../echo/echo.service';
import {ToastrService} from 'ngx-toastr';
import {
  DescargarReporteService
} from '../../../../application/adminstrador/services/descargar-reporte/descargar-reporte.service';
import {saveAs} from 'file-saver';
import {GestionToastrService} from '../../../../application/shared/services/gestion-toastr/gestion-toastr.service';
import {ActiveToast} from 'ngx-toastr/toastr/toastr.service';
import {
  ObtenerNumeroDocumentosSinLeerUsuarioService
} from '../../../../application/shared/services/obtener-numero-documentos-sin-leer-usuario/obtener-numero-documentos-sin-leer-usuario.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit, AfterViewInit {
  public rol: any;

  @HostListener('window:storage')
  public onStorageChange(event: StorageEvent) {
    this.ngOnInit();
  }

  public currentRoute = '';
  public menuItems = [] as MenuItem[];
  public isShown = false;
  public form: UntypedFormGroup;
  public menuVisible = false;
  public menuItem: MenuItem;
  private informacionTrabajador = {} as InformacionTrabajador;
  private toastDescarga: ActiveToast<any>;
  private licenciaVenciada = false;

  @Input() set esLicenciaVencida(value: boolean) {
    this.licenciaVenciada = value;
    if (value) {
      this.menuItems = this.getFirstAndLast(this.menuItems);
    }
  }

  constructor(private menuService: MenuService,
              private router: Router,
              private trabajadorService: TrabajadorService,
              private authenticationService: AuthenticationService,
              private toastrService: ToastrService,
              private localStorageService: LocalStorageService,
              private descargarReporteService: DescargarReporteService,
              private echoService: EchoService,
              private gestionToastrService: GestionToastrService,
              private obtenerNumeroDocumentosSinLeerUsuarioService: ObtenerNumeroDocumentosSinLeerUsuarioService) {
    this.rol = this.localStorageService.getAuthenticatedUser().role;
    router.events.subscribe((val) => {
      this.currentRoute = this.router.url;
    });
  }

  public ngOnInit(): void {
    window.addEventListener('generandoReporte', (event: CustomEvent) => {
      this.gestionToastrService.addToastr(event.detail as ActiveToast<any>);
    });

    const user = this.localStorageService.getAuthenticatedUser();
    window.addEventListener('usuarioSuplantado', (event: CustomEvent) => {
      this.menuService.getMenu(user.role).subscribe(menuItems => {
        this.menuItems = menuItems;
        this.navigateFirstItem();
      });
    });
    this.echoService.getEcho().unbind_global();

    if (user) {
      this.echoService.getEcho().unsubscribe('private-descargas.' + user.id);
      this.echoService.getEcho().unsubscribe('private-documento-adjunto-usuario.' + user.id);
      this.echoService.getEcho().unsubscribe('private-usuario.' + user.id);
      this.echoService.getEcho().subscribe('private-usuario.' + user.id).bind('rol-cambiado', (data) => {
        this.menuService.getMenu(data.role).subscribe(menuItems => {
          const user = this.localStorageService.getAuthenticatedUser();
          user.role = Number(data.role);
          localStorage.setItem('usuario', JSON.stringify(user));
          this.menuItems = menuItems;
          this.navigateFirstItem();
          this.obtenerNumeroDocumentosSinLeer();
        });
      });
      this.echoService.getEcho().subscribe('private-documento-adjunto-usuario.' + user.id);
      this.echoService.getEcho().subscribe('private-documento-adjunto-usuario.' + user.id)
        .bind('descargado', () => {
          this.obtenerNumeroDocumentosSinLeer();
        }).bind('creado', () => {
        this.obtenerNumeroDocumentosSinLeer();
      });
      this.echoService.getEcho()
        .subscribe('private-descargas.' + user.id)
        .bind('descargar', (data: any) => {
          this.gestionToastrService.removeToaster();
          const reporte = data.reporte;
          this.toastDescarga = this.toastrService.info(
            `Se ha generado el reporte solicitado con éxito, haga clic aquí para descargarlo.`,
            'Reporte generado',
            {
              tapToDismiss: false,
              closeButton: true,
              disableTimeOut: true,
              toastClass: 'ngx-toastr toast-descarga',
            });

          this.toastDescarga.onTap.subscribe(() => {
            this.descargarReporte(reporte, true);
          });
        });

      if (user.role === 3) {
        this.echoService.getEcho().unsubscribe('private-fichaje-app-empresa.' + this.informacionTrabajador.empresa_id);
        zip(this.trabajadorService.getInformacionTrabajador(),
          this.menuService.getMenu(user.role)).subscribe(([informacion, menuItems]) => {
          this.menuItems = menuItems;
          this.informacionTrabajador = informacion;
          this.echoService.getEcho().subscribe('private-fichaje-app-empresa.' + this.informacionTrabajador.empresa_id)
            .bind('configuracion-app', () => {
              this.menuService.getMenu(user.role).subscribe(menuItems => {
                this.menuItems = menuItems;
                this.navigateFirstItem();
              });
            });
          this.echoService.getEcho().unsubscribe('private-documento-adjunto-trabajador.' + this.informacionTrabajador.id);
          this.echoService.getEcho().subscribe('private-documento-adjunto-trabajador.' + this.informacionTrabajador.id)
            .bind('descargado', () => {
              this.obtenerNumeroDocumentosSinLeer();
            }).bind('creado', () => {
            this.obtenerNumeroDocumentosSinLeer();
          });

          this.echoService.getEcho().unsubscribe('private-documento-adjunto-empresa.' + this.informacionTrabajador.empresa_id);
          this.echoService.getEcho().subscribe('private-documento-adjunto-empresa.' + this.informacionTrabajador.empresa_id)
            .bind('descargado', () => {
              this.obtenerNumeroDocumentosSinLeer();
            }).bind('creado', () => {
            this.obtenerNumeroDocumentosSinLeer();
          });
          this.informacionTrabajador.equipos_pertenece.forEach((equipo) => {
            this.echoService.getEcho().unsubscribe('private-documento-adjunto-equipo.' + equipo.id);
            this.echoService.getEcho().subscribe('private-documento-adjunto-equipo.' + equipo.id)
              .bind('descargado', () => {
                this.obtenerNumeroDocumentosSinLeer();
              }).bind('creado', () => {
              this.obtenerNumeroDocumentosSinLeer();
            });
          });
        });
      } else {
        this.menuService.getMenu(user.role).subscribe(menuItems => {
          this.menuItems = this.licenciaVenciada ? this.getFirstAndLast(menuItems) : menuItems;
          this.navigateFirstItem();
        });
      }
    }
  }

  public ngAfterViewInit(): void {
    this.obtenerNumeroDocumentosSinLeer();
  }

  public toggle(): void {
    this.isShown = !this.isShown;
  }

  public cerrarSesion(): void {
    this.authenticationService.logOut();
  }

  ocultarMenu() {
    this.menuVisible = false;
    document.body.style.overflow = 'auto';
  }

  public mostrarMenu(menuItem: MenuItem) {
    if (menuItem.childItems.length > 0) {
      document.body.style.overflow = 'hidden';
      this.menuItem = menuItem;
      this.menuVisible = true;
    } else {
      document.body.style.overflow = 'auto';
      this.router.navigate([menuItem.url]);
      this.menuVisible = false;
    }
  }

  public isActivo(menuItem: MenuItem) {
    let activo = false;
    if (menuItem.childItems.length === 0 && this.currentRoute.search(menuItem.url) !== -1) {
      activo = true;
    } else {
      menuItem.childItems.forEach(child => {
        if (this.currentRoute.search(child.url) !== -1) {
          activo = true;
        }
      });
    }
    return activo;

  }

  public navegar(url: string) {
    document.body.style.overflow = 'auto';
    this.menuVisible = false;
    this.router.navigate([url]);
  }

  public showElementoMenu(menuItem: MenuItem): boolean {
    if (menuItem) {
      return !((menuItem.url === '/fichajes' || menuItem.url === '/ausencias-equipo' || menuItem.url === '/entradas-salidas' || menuItem.title === 'Gestión equipo')
        && !this.informacionTrabajador.es_responsable_equipo);
    } else {
      return true;
    }
  }

  private navigateFirstItem(): void {
    if (this.router.url === '/') {
      this.router.navigate([this.menuItems[0].url]);
    }
  }

  private descargarReporte(urlTemporal: string, debeBorrarToastr: boolean) {
    this.descargarReporteService.__invoke(urlTemporal).subscribe((result) => {
      saveAs(result, 'reporte.xlsx');
      if (debeBorrarToastr) {
        this.toastrService.clear(this.toastDescarga.toastId);
      }
    }, errorResponse => {
      this.toastrService.error('Ha ocurrido un error al descargar el reporte.', 'Error');
    });
  }

  public obtenerNumeroDocumentosSinLeer(): void {
    const rol = this.localStorageService.getCurrentUserRole();
    if (rol !== 1) {
      this.obtenerNumeroDocumentosSinLeerUsuarioService.invoke().subscribe({
        next: (numeroDocumentos) => {
          const documentos = document.getElementById('Documentos');
          if (numeroDocumentos > 0) {
            const itemDocumentos = this.menuItems.filter((item) => item.title === 'Documentos');
            if (itemDocumentos.length > 0) {
              itemDocumentos[0].badgeCount = numeroDocumentos;
              documentos.setAttribute('data-count', String(numeroDocumentos));
            }
          } else {
            if (documentos) {
              if (documentos.hasAttribute('data-count')) {
                documentos.removeAttribute('data-count');
              }
            }
          }
        }, error: (errorResponse) => {

        }
      });
    }
  }

  public getFirstAndLast(array: any[]): any[] {
    return [array[0], array[array.length - 1]];
  }
}
