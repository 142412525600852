import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {WidgetFichajeService} from './widget-fichaje.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {IniciarDescansoService} from './iniciar-descanso.service';
import {FinalizarDescansoService} from './finalizar-descanso.service';

@Component({
  selector: 'lib-widget-fichaje',
  templateUrl: 'widget-fichaje.component.html',
  styles: ['.background-descanso {background-color: #e3d9f4;} .btn-descanso {color: black;background-color: #e3d9f4;} .btn-descanso:hover {color: black;background-color: #d2c6e7;}']
})
export class WidgetFichajeComponent implements OnInit {
  public creandoRegistro = false;

  @Input('token_integracion_presencia')
  set setTokenIntegracionPresencia(tokenIntegracionPresencia) {
    if (tokenIntegracionPresencia) {
      this.token = tokenIntegracionPresencia;
    }
    this.inicializarFichajes();
  }

  private token: string;
  public duracionJornada = '0h 0min';
  public ultimoMovimiento = {} as any;
  public siteUrl = 'https://app.tyver.es';
  private configuracionApp: any;
  private crearRegistroForm: UntypedFormGroup;
  @Output() tokenSet: EventEmitter<string> = new EventEmitter<string>();
  @Output() tokenDeleted: EventEmitter<null> = new EventEmitter<null>();

  constructor(private widgetFichajeService: WidgetFichajeService,
              private iniciarDescansoService: IniciarDescansoService,
              private finalizarDescansoService: FinalizarDescansoService,
              private formBuilder: UntypedFormBuilder) {
  }

  public ngOnInit(): void {
  }

  private inicializarFichajes() {
    this.buildForm();
    this.widgetFichajeService.getConfiguracionApp(this.token).subscribe(configuracionApp => {
      this.configuracionApp = configuracionApp as any;
    });
    this.getDuracionEstancia();
    this.getUltimoMovimiento();
  }

  private buildForm(): void {
    this.crearRegistroForm = this.formBuilder.group({
      tipo: this.ultimoMovimiento.tipo === 1 ? 2 : 1,
      observacion: null,
      latitud: null,
      longitud: null,
      categoria_estancia_id: null,
      origen_fichaje: 3,
      fichaje_forzoso: 0
    });
  }

  public loginControlPresencia(): void {
    const popup = window.open(this.siteUrl + '/auth/login/integracion', 'MsgWindow', 'width=800,height=600');
    window.addEventListener('message', (event) => {
      if (event.origin !== this.siteUrl) {
        return;
      }

      popup.close();

      this.setTokenAfterLogin(event.data.token);

    }, false);
  }

  public presentAlertCrearRegistro(tipoMovimiento: number): void {
    let tipoMovimientoDescripcion = '';

    switch (tipoMovimiento) {
      case 1:
        tipoMovimientoDescripcion = 'entrada';
        break;
      case 2:
        tipoMovimientoDescripcion = 'salida';
        break;
      case 3:
        tipoMovimientoDescripcion = 'inicio descanso';
        break;
      case 4:
        tipoMovimientoDescripcion = 'fin descanso';
        break;
    }

    if (confirm('¿Desea registrar su ' + tipoMovimientoDescripcion + '?')) {
      this.enviarFichaje(tipoMovimiento);
    } else {
      this.creandoRegistro = false;
    }
  }

  private enviarFichaje(tipoMovimiento: number): void {
    if (tipoMovimiento === 3 || tipoMovimiento === 4) {
      this.crearRegistroDescanso(tipoMovimiento);
    } else {
      this.crearRegistro();
    }
  }

  private crearRegistro(): void {
    this.widgetFichajeService.crearRegistroEntradaSalida(this.crearRegistroForm.value, this.token).subscribe(() => {
      this.getDuracionEstancia();
      this.getUltimoMovimiento();
      this.crearRegistroForm.patchValue({observacion: null});
      this.creandoRegistro = false;
    }, () => {
      this.creandoRegistro = false;
    });
  }

  private setTokenAfterLogin(token: string): void {
    this.token = token;
    this.tokenSet.emit(token);
    this.inicializarFichajes();
  }

  private getDuracionEstancia() {
    this.widgetFichajeService.getDuracionEstanciaTrabajador(this.token).subscribe(estancia => {
      this.duracionJornada = estancia.duracion;
    });
  }

  private getUltimoMovimiento() {
    this.widgetFichajeService.getUltimoEstado(this.token).subscribe(ultimoMovimiento => {
      this.ultimoMovimiento = ultimoMovimiento;
      this.crearRegistroForm.patchValue({
        tipo: this.ultimoMovimiento.tipo === 1 ? 2 : 1
      });
    }, () => {
      this.eliminaTokenToken();
    });
  }

  public tieneToken(): boolean {
    return !!this.token;
  }

  public compruebaEstadoFichaje() {
    this.creandoRegistro = true;
    this.widgetFichajeService.getUltimoEstado(this.token).subscribe(ultimoMovimiento => {
      this.ultimoMovimiento = ultimoMovimiento;
      const tipoMovimiento = this.ultimoMovimiento.tipo === 1 ? 2 : 1;
      this.crearRegistroForm.patchValue({
        tipo: tipoMovimiento
      });
      this.presentAlertCrearRegistro(tipoMovimiento);
    }, error => {
      this.creandoRegistro = false;
    });
  }


  public accionDescanso(): void {
    this.creandoRegistro = true;
    this.widgetFichajeService.getUltimoEstado(this.token).subscribe((ultimoMovimiento) => {
      this.ultimoMovimiento = ultimoMovimiento;
      const tipoMovimiento = this.ultimoMovimiento.descanso_activo ? 4 : 3;
      this.crearRegistroForm.patchValue({
        tipo: tipoMovimiento
      });
      this.presentAlertCrearRegistro(tipoMovimiento);
    }, error => {
      this.creandoRegistro = false;
    });
  }

  public crearRegistroDescanso(tipoMovimiento: number): void {
    if (tipoMovimiento === 4) {
      this.finalizarDescansoService.__invoke(this.crearRegistroForm.value, this.token).subscribe({
        next: (descanso) => {
          this.creandoRegistro = false;
          if (descanso) {
            this.creandoRegistro = false;
            this.getUltimoMovimiento();
          } else {
          }
        },
        error: () => {
          this.creandoRegistro = false;
        }
      });
    } else {
      this.iniciarDescansoService.__invoke(this.crearRegistroForm.value, this.token).subscribe({
        next: (descanso) => {
          this.creandoRegistro = false;
          this.getUltimoMovimiento();
        },
        error: () => {
          this.creandoRegistro = false;
        }
      });
    }
  }

  private eliminaTokenToken(): void {
    this.token = null;
    this.tokenDeleted.emit();
  }
}
