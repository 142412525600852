export const environment = {
  apiUrl: '/api',
  authUrl: '/api/auth',
  apiUrlTrabajador: '/api/backoffice-trabajador',
  apiUrlSuperAdministrador: '/api/backoffice-superadministrador',
  printUrl: '',
  mediaUrl: '',
  redirectUrl: 'https://app.tyver.es/',
  websockets: 'app.tyver.es',
  websocketsTransport: 'wss',
  websocketsPath: null,
  encrypted: true,
  production: true,
  googleClientId: '425370534631-iic3qlcu6nj3kbiogh85uu7j7a9cdvfa.apps.googleusercontent.com',
  appkey: '8dc343551f533e56961f',
  braintreeToken: 'sandbox_fwpv2xc8_kj3j8vqts33gkxyw',
  googleAnalyticsId: 'G-YK3663GGTC'
};
