import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import localeEs from '@angular/common/locales/es';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {UserInfoComponent} from './infraestructure/layout/header-layout/header/user-info/user-info.component';
import {HeaderComponent} from './infraestructure/layout/header-layout/header/header.component';
import {MenuComponent} from './infraestructure/layout/header-layout/menu/menu.component';
import {FullScreenLayoutComponent} from './infraestructure/layout/full-screen-layout/full-screen-layout.component';
import {HeaderLayoutComponent} from './infraestructure/layout/header-layout/header-layout.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppResolverService} from './app-resolver.service';
import {environment} from '../environments/environment';
import {DatePipe, registerLocaleData} from '@angular/common';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {ValidationService} from './infraestructure/validation/validation.service';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG, MsalBroadcastService, MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MsalModule, MsalService
} from '@azure/msal-angular';
import {JwtInterceptorService} from './application/authentication/jwt-interceptor.service';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {SuperAdministradorRoutingModule} from './domain/super-administrador/super-administrador-routing.module';
import {AdministradorRoutingModule} from './domain/administrador/administrador-routing.module';
import {
  ProbarWebComponentsLayoutComponent
} from './infraestructure/layout/probar-web-components-layout/probar-web-components-layout.component';
import {WidgetFichajeModule} from '../../projects/widget-fichaje/src/lib/widget-fichaje.module';
import {PublicRoutingModule} from './domain/public/public-routing.module';
import {
  GetInformacionLicenciaModule
} from './application/shared/modules/get-informacion-licencia/get-informacion-licencia.module';
import {
  GoogleInitOptions,
  GoogleLoginProvider,
  SocialAuthServiceConfig,
  SocialLoginModule
} from '@abacritt/angularx-social-login';
import {PantallaCompletaRegistroComponent} from './infraestructure/layout/pantalla-completa-registro/pantalla-completa-registro.component';
import {ObtenerContrasteColorService} from './infraestructure/services/obtener-contraste-color.service';
import * as Sentry from '@sentry/angular';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {Router} from '@angular/router';
import {InteractionType, IPublicClientApplication, PublicClientApplication} from '@azure/msal-browser';
import {NgMapsCoreModule} from '@ng-maps/core';
import {GOOGLE_MAPS_API_CONFIG} from '@ng-maps/google';
import {PreviewSoporteModule} from "./application/shared/modules/preview-soporte/preview-soporte.module";
import {
  PreviewConfiguracionNotificacionesModule
} from "./application/shared/modules/preview-configuracion-notificaciones/preview-configuracion-notificaciones.module";

registerLocaleData(localeEs, 'es');

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + '/public/langs/', '');
}

export function startupServiceFactory(appResolverService: AppResolverService): () => void {
  return () => appResolverService.load();
}

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false,
};

// Lista de patrones de error a ignorar
const ignoredErrorPatterns = [
  '0 Unknown Error',
  '401 Unauthorized',
  '403 Forbidden',
  '404 Not Found',
  '500 Internal Server Error',
  '502 Bad Gateway',
  '503 Service Unavailable',
  '504 Gateway Timeout',
  'Http failure during parsing for .*' // Captura cualquier URL
];

// Construcción de la expresión regular combinando los patrones
const serverErrorsRegex = new RegExp(
  ignoredErrorPatterns.join('|'),
  'mi'
);
Sentry.init({
  dsn: 'https://b1876c9fcc2845d789566a18ed071990@sentry.lunia.es/23',
  integrations: [],
  ignoreErrors: [serverErrorsRegex],
  tracesSampleRate: 0.0,
  tracePropagationTargets: ['localhost', 'https://app.tyver.es/'],
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
});

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: 'd702bce3-d44b-42b1-8189-e08157435fac',
      redirectUri: environment.redirectUrl
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: undefined,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderLayoutComponent,
    FullScreenLayoutComponent,
    MenuComponent,
    HeaderComponent,
    UserInfoComponent,
    ProbarWebComponentsLayoutComponent,
    PantallaCompletaRegistroComponent,
  ],
  imports: [
    BrowserModule,
    NgMapsCoreModule,
    AppRoutingModule,
    HttpClientModule,
    MsalModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    SocialLoginModule,
    NgxUiLoaderModule,
    PublicRoutingModule,
    SuperAdministradorRoutingModule,
    AdministradorRoutingModule,
    WidgetFichajeModule,
    GetInformacionLicenciaModule,
    PreviewSoporteModule,
    PreviewConfiguracionNotificacionesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptorService,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-ES'
    },
    {provide: APP_INITIALIZER, useFactory: startupServiceFactory, deps: [AppResolverService, HttpClient], multi: true},
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId,
              googleLoginOptions
            )
          },
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
    {provide: LOCALE_ID, useValue: 'es-Es'},
    ObtenerContrasteColorService,
    ValidationService,
    DatePipe,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyCkx_SWaMLGGcCU7G5MZT69b9oAu_DUtx8',
        libraries: ['places']
      }
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class AppModule {
}

if (!(window as any).isAppModuleLoaded) {
  (window as any).isAppModuleLoaded = true;
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch(err => console.error(err));
}
