@if (!formularioSoporteVisible) {
  @if (isPanelVisible) {
    <div class="sticky-top bg-white overflow-x-hidden">
      <div class="modal-header bg-white preview preview-header-widget border-bottom">
        <h2 class="modal-title ps-3 text-dark">{{ 'Centro de ayuda' | translate }}</h2>
        <button (click)="cierraPanel('soporte')" aria-label="Close" class="btn-close me-2" type="button"></button>
      </div>
    </div>
    <iframe [src]="sanitizedUrl" loading=lazy [style]="calcularEstilosIframe()"></iframe>
    <div class="w-100 modal-left bg-primary flex align-items-center c-pointer position-absolute bottom-0"
         (click)="toggleFormularioSoporte()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" style="width: 1.3rem; height: 1.3rem" fill="white">
        <path
          d="M305 239c9.4 9.4 9.4 24.6 0 33.9L113 465c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l175-175L79 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L305 239z"/>
      </svg>
      <a class="btn btn-link text-16 text-white">
        {{ 'Ponte en contacto ' | translate }}
      </a>
    </div>
  }
} @else {
  <app-soporte (cerrar)="toggleFormularioSoporte()"></app-soporte>
}




